/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ConnectionFragment = { __typename?: 'ExternalConnectionType', id: string, created: any, dataSchema: any, isStreaming: boolean | null | undefined, connectionParameters: any | null | undefined, connectionName: string, connectionType: Types.ExternalConnectionConnectionType, deploymentEnv: string | null | undefined };

export type DataSourceFragment = { __typename?: 'DataSourceType', id: string, name: string, type: string | null | undefined, created: any, modified: any, useProductionSettingsForDraft: boolean, connections: Array<{ __typename?: 'ExternalConnectionType', id: string, created: any, dataSchema: any, isStreaming: boolean | null | undefined, connectionParameters: any | null | undefined, connectionName: string, connectionType: Types.ExternalConnectionConnectionType, deploymentEnv: string | null | undefined } | null | undefined> | null | undefined };

export const ConnectionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ConnectionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExternalConnectionType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"dataSchema"},"name":{"kind":"Name","value":"data_schema"}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","alias":{"kind":"Name","value":"isStreaming"},"name":{"kind":"Name","value":"is_streaming"}},{"kind":"Field","alias":{"kind":"Name","value":"connectionParameters"},"name":{"kind":"Name","value":"connection_parameters"}},{"kind":"Field","alias":{"kind":"Name","value":"connectionName"},"name":{"kind":"Name","value":"connection_name"}},{"kind":"Field","alias":{"kind":"Name","value":"connectionType"},"name":{"kind":"Name","value":"connection_type"}},{"kind":"Field","alias":{"kind":"Name","value":"deploymentEnv"},"name":{"kind":"Name","value":"deployment_env"}}]}}]} as unknown as DocumentNode<ConnectionFragment, unknown>;
export const DataSourceFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DataSourceFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DataSourceType"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"connections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionFragment"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"useProductionSettingsForDraft"},"name":{"kind":"Name","value":"use_production_settings_for_draft"}},{"kind":"Field","name":{"kind":"Name","value":"created"}},{"kind":"Field","name":{"kind":"Name","value":"modified"}}]}}]} as unknown as DocumentNode<DataSourceFragment, unknown>;
export const namedOperations = {
  Fragment: {
    ConnectionFragment: 'ConnectionFragment',
    DataSourceFragment: 'DataSourceFragment'
  }
}
