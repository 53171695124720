import { useCallback } from 'react';

import { useLocalStorage } from 'react-use';

function useDismissable(id: string) {
  const [dismissed, setDismissed] = useLocalStorage<boolean>(id);

  const dismiss = useCallback(() => {
    setDismissed(true);
  }, [setDismissed]);

  return {
    isDismissed: dismissed,
    dismiss,
  };
}

export default useDismissable;
