import { SvgIconProps } from '@mui/material/SvgIcon';

import { SvgIcon } from './styled';

function DeepSeekIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="31" height="23" viewBox="0 0 31 23" {...props}>
      <path
        d="M30.1847 1.8522C29.8672 1.69719 29.7297 1.99345 29.5447 2.1447C29.4809 2.19345 29.4272
        2.2572 29.3734 2.3147C28.9084 2.81095 28.3659 3.13595 27.6572 3.0972C26.6209 3.0397 25.7359
        3.3647 24.9534 4.1572C24.7872 3.1797 24.2347 2.59719 23.3947 2.22219C22.9547 2.02719 22.5097
        1.83344 22.2009 1.40969C21.9859 1.10844 21.9272 0.772195 21.8197 0.442195C21.7509 0.242195
        21.6822 0.038445 21.4534 0.00469501C21.2034 -0.034055 21.1059 0.174695 21.0084 0.349695C20.6172
        1.0647 20.4659 1.8522 20.4809 2.64969C20.5147 4.44469 21.2722 5.87469 22.7784 6.89094C22.9497
        7.00719 22.9934 7.1247 22.9397 7.2947C22.8372 7.6447 22.7147 7.98469 22.6072 8.33594C22.5384
        8.55969 22.4359 8.60719 22.1959 8.51094C21.385 8.16244 20.6484 7.66174 20.0259 7.03594C18.9547
        6.00094 17.9872 4.85845 16.7797 3.96345C16.4999 3.75672 16.2126 3.56033 15.9184 3.37469C14.6872
        2.17844 16.0809 1.19595 16.4034 1.0797C16.7409 0.957195 16.5197 0.539695 15.4297 0.544695C14.3397
        0.549695 13.3422 0.913445 12.0709 1.39969C11.882 1.47208 11.6877 1.52935 11.4897 1.57095C10.3017
        1.34711 9.08675 1.30413 7.88594 1.44345C5.52969 1.70595 3.64844 2.82095 2.26469 4.7222C0.602193
        7.00719 0.210943 9.6047 0.689693 12.3122C1.19344 15.1672 2.65094 17.5309 4.88969 19.3784C7.21219
        21.2947 9.88594 22.2334 12.9372 22.0534C14.7897 21.9472 16.8534 21.6984 19.1797 19.7284C19.7672
        20.0209 20.3822 20.1372 21.4047 20.2247C22.1922 20.2984 22.9497 20.1872 23.5359 20.0647C24.4547
        19.8697 24.3909 19.0184 24.0597 18.8634C21.3659 17.6084 21.9572 18.1197 21.4184 17.7059C22.7884
        16.0859 24.8509 14.4034 25.6584 8.95219C25.7209 8.51844 25.6672 8.24594 25.6584 7.89595C25.6534
        7.68345 25.7022 7.59969 25.9459 7.57594C26.6222 7.50579 27.2783 7.30409 27.8772 6.9822C29.6222
        6.02845 30.3272 4.46344 30.4934 2.58594C30.5184 2.29844 30.4884 2.00344 30.1847 1.8522ZM14.9759
        18.7497C12.3647 16.6972 11.0984 16.0209 10.5759 16.0497C10.0859 16.0797 10.1747 16.6384 10.2822
        17.0034C10.3947 17.3634 10.5409 17.6109 10.7459 17.9272C10.8884 18.1359 10.9859 18.4472 10.6047
        18.6809C9.76344 19.2009 8.30219 18.5059 8.23344 18.4722C6.53219 17.4697 5.10844 16.1472 4.10719
        14.3384C3.13969 12.5972 2.57719 10.7297 2.48469 8.73594C2.45969 8.25345 2.60094 8.08345 3.08094
        7.99594C3.71108 7.87536 4.35673 7.85889 4.99219 7.94719C7.65719 8.33719 9.92469 9.52845 11.8272
        11.4147C12.9122 12.4897 13.7334 13.7734 14.5797 15.0284C15.4797 16.3609 16.4472 17.6309 17.6797
        18.6709C18.1147 19.0359 18.4609 19.3134 18.7934 19.5172C17.7909 19.6297 16.1184 19.6547 14.9759
        18.7497ZM16.2259 10.6997C16.2257 10.6376 16.2406 10.5764 16.2694 10.5213C16.2981 10.4663 16.3398
        10.4191 16.3909 10.3838C16.4419 10.3484 16.5008 10.3261 16.5625 10.3186C16.6241 10.3112 16.6867
        10.3188 16.7447 10.3409C16.8186 10.3675 16.8825 10.4164 16.9273 10.4809C16.9721 10.5455 16.9957
        10.6224 16.9947 10.7009C16.9949 10.7517 16.9849 10.802 16.9655 10.8489C16.946 10.8958 16.9174
        10.9383 16.8813 10.974C16.8453 11.0097 16.8025 11.0379 16.7554 11.0569C16.7083 11.0759 16.6579
        11.0854 16.6072 11.0847C16.5568 11.0849 16.5069 11.075 16.4604 11.0556C16.4139 11.0363 16.3717
        11.0079 16.3363 10.972C16.3009 10.9362 16.2731 10.8936 16.2543 10.8468C16.2356 10.8001 16.2251
        10.7501 16.2259 10.6997ZM20.1134 12.6947C19.8634 12.7959 19.6147 12.8834 19.3759 12.8947C19.0168
        12.9072 18.6643 12.795 18.3784 12.5772C18.0359 12.2897 17.7909 12.1297 17.6884 11.6297C17.6531
        11.3853 17.6599 11.1368 17.7084 10.8947C17.7959 10.4859 17.6984 10.2234 17.4097 9.98594C17.1759
        9.79095 16.8772 9.7372 16.5497 9.7372C16.4376 9.73069 16.3287 9.69724 16.2322 9.63969C16.0947
        9.5722 15.9822 9.40219 16.0897 9.19219C16.1247 9.12469 16.2897 8.95969 16.3297 8.9297C16.7747
        8.67719 17.2884 8.7597 17.7622 8.94969C18.2022 9.12969 18.5347 9.45969 19.0134 9.9272C19.5022
        10.4909 19.5909 10.6472 19.8697 11.0697C20.0897 11.4009 20.2897 11.7409 20.4259 12.1297C20.5097
        12.3734 20.4022 12.5722 20.1134 12.6947Z"
        fill="#4D6BFE"
      />
    </SvgIcon>
  );
}

export default DeepSeekIcon;
