import { MouseEvent } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import DeepSeekIcon from '@/components/Icons/DeepSeekIcon';
import XIcon from '@/components/Icons/XIcon';
import { useAsOperator } from '@/hooks';
import useDeepSeekPlaygroundLinkEnabled from '@/hooks/useDeepSeekPlaygroundLinkEnabled';
import useDismissable from '@/hooks/useDismissable';
import { links } from '@/localization';
import { BasetenPageEnum, usePageSwitcher } from '@/routes';

import Credits from './Credits';
import HelpMenu from './HelpMenu';
import PaymentOverdue from './PaymentOverdue';
import UserMenu from './UserMenu';

const DEEP_SEEK_PLAYGROUND_LOCAL_STORAGE_KEY = 'deep_seek_playground_link';

function LoggedIn() {
  const asOperator = useAsOperator();
  const gpToPage = usePageSwitcher();
  const isDeepSeekPlaygroundLinkEnabled = useDeepSeekPlaygroundLinkEnabled();
  const { isDismissed, dismiss } = useDismissable(DEEP_SEEK_PLAYGROUND_LOCAL_STORAGE_KEY);

  function dismissDeepSeekPlaygroundLink(ev: MouseEvent<SVGElement>) {
    ev.stopPropagation();
    dismiss();
  }

  function goToDeepSeekPlayground() {
    gpToPage(BasetenPageEnum.Playground);
  }

  return (
    <Stack gap={1} direction="row" alignItems="center">
      {/* TODO (Suren -> Aaron): this color is missing in our theme */}
      {isDeepSeekPlaygroundLinkEnabled && !isDismissed && (
        <Button
          variant="contained"
          onClick={goToDeepSeekPlayground}
          sx={{ backgroundColor: '#4D6BFE' }}
          startIcon={<DeepSeekIcon sx={{ path: { fill: 'white' } }} />}
          endIcon={<XIcon onClick={dismissDeepSeekPlaygroundLink} />}
        >
          Try DeepSeek R1
        </Button>
      )}
      <Credits />
      <PaymentOverdue />

      <Stack direction="row" alignItems="center" gap={0.5}>
        {!asOperator && ENV_CONFIG.MODEL_LIBRARY_ENABLED && (
          <Button
            variant="text"
            color="primary"
            href={links.basetenModelLibrary}
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            Model Library
          </Button>
        )}
        <HelpMenu />
        <UserMenu />
      </Stack>
    </Stack>
  );
}

export default LoggedIn;
