import { useCheckFeatureFlag } from './useFeatureFlags';
import { FeatureFlags } from './useFeatureFlags/types';

function useDeepSeekPlaygroundLinkEnabled() {
  const checkFeatureFlag = useCheckFeatureFlag();

  return checkFeatureFlag(FeatureFlags.DEEPSEEK_PLAYGROUND_LINK_ENABLED);
}

export default useDeepSeekPlaygroundLinkEnabled;
