import { Stack } from '@mui/material';
import { styled } from '@mui/system';

import { getBorder } from '@/theme/utils';

const DialogContent = styled('div')({
  marginBottom: '1em',
});

const ModalBox = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  maxWidth: 800,
}));

const ModalFooter = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  background: theme.palette.background.subtle,
  borderTop: getBorder(theme.palette.border.default),
}));

const ModalForm = styled('form')({});

ModalForm.defaultProps = {
  action: '/',
};

export { DialogContent, ModalBox, ModalFooter, ModalForm };
